.solution-box-container {
  height: auto;
  width: 20vw;
  display: flex;
  flex-direction: column;
  /* padding: 15px; */

  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  /* margin: 10px; */
  cursor: pointer;
}

.solution-image {
  /* width: 10vw;
  height: 50%; */
  height: 150px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;
}

.solution-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
}

.solution-box-container:hover {
  transform: scale(1.1);
  /* background: #ffffff; */
  box-shadow: 0;
}

@media only screen and (max-device-width: 760px) {
  .solution-box-container {
    width: 90%;
  }
}
