.framework-box-container {
  height: auto;
  width: 20vw;
  background: #fff;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.framework-image {
  /* box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff; */
  width: 95%;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
}

.framework-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
}

@media only screen and (max-device-width: 760px) {
  .framework-box-container {
    width: 90%;
  }
}
