
.project-carousel-main-container{
    height: fit-content;
    width: 100%;
    /* background: rgb(255, 255, 255); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5vh;
}

.project-carousel-title{
    /* height: 10vh; */
    width: 90%;
    /* background: rgb(139, 139, 139); */
    display: flex;
    align-items: center;
    font: var(--primary-font);
    /* font-style: normal; */
    font-weight: 900;
    font-size: var(--font-size-1);
    color: #000000;
    text-align: left;
}

.project-content{
    /* height: 10vh; */
    width: 90%;
    /* background: rgb(139, 139, 139); */
    display: flex;
    align-items: center;
    font: var(--primary-font);
    /* font-style: normal; */
    font-weight: 400;
    font-size: var(--font-size-3);
    color: #000000;
    text-align: left;
}

@media only screen and (max-device-width: 760px) {
    .project-carousel-main-container{
        display: none;
    }
}