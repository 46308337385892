.testimonial-box-container {
  height: 40%;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  padding: 3%;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  /* margin: 10px; */
}

.testimonial-image {
  /* width: 10vw;
  height: 50%; */
  height: 200px;
  object-fit: cover;
  width: 200px;
  border-radius: 50%;
  align-self: center;
}

.testimonial-text-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
}

.testimonial-title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  margin-bottom: -0.5vh;
  font-size: var(--font-size-3);
  color: var(--font-color-3);
}
.testimonial-description {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  display: flex;
  word-break: break-word;
  font-size: var(--font-size-4);
  color: var(--font-color-3);
}
